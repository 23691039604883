import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('encounters', [
      'opNoteBloodLosses',
    ]),
  },
  methods: {
    getBoneLossById(id) {
      return this.opNoteBloodLosses[this.opNoteBloodLosses.findIndex(x => x.id === id)].title
    },
    generateFinalNoteBody() {
      const a1cLevel = this.encounter.a1c_level
      const dfuRisk = this.encounter.dfu_risk
      const dfuRiskReason = this.encounter.dfu_risk_reason
      const encounterId = this.encounter.id
      const footwearEvaluation = this.encounter.footwear_evaluation
      const footwearEvaluationReason = this.encounter.footwear_evaluation_reason
      const footwearExamReason = this.encounter.footwear_exam_reason
      const footwearNotableIssues = this.encounter.footwear_notable_issues
      const footwearEvaluationDate = null
      let finalNoteBody = ' <br><br>' // Space is added at the start so it doesn't remove the line feeds
      if (dfuRisk || footwearEvaluation || a1cLevel) {
        if (a1cLevel) {
          finalNoteBody += 'ANNUAL A1c REPORTING:<br>'
          if (a1cLevel === 'not performed') {
            finalNoteBody += 'Hemoglobin A1c level was not performed during the measurement period (12 months).  '
          } else {
            finalNoteBody += `Most recent hemoglobin A1c (HbA1c) level: ${a1cLevel}`
          }
        }
        if (dfuRisk === 'informed') {
          finalNoteBody += '<br><br>ANNUAL DFU RISK EDUCATION:<br>'
          finalNoteBody += `${this.$custom.randomString(['The patient was counseled on the risks that could lead to development of diabetic foot ulcers',
            'Patient was counseled on the risks of developing diabetic foot ulcers',
            'Patient was educated with regard to factors that increase the risk of developing a diabetic foot ulcer',
            'Patient was briefed on issues that can increase the risks of developing a diabetic foot ulcer'])} on ${this.formatDate(this.encounter.visit_date)}.  `
        } else if (dfuRisk === 'not accepted') {
          finalNoteBody += '<br><br>DFU RISK EDUCATION:<br>'
          finalNoteBody += 'Patient was unable to accept diabetic foot'
          finalNoteBody += ` ulcers risk education on ${this.formatDate(this.encounter.visit_date)}. ${dfuRiskReason}.`
        }

        if (footwearEvaluation) {
          if (dfuRisk) {
            finalNoteBody += '<br><br>'
          }
          finalNoteBody += 'ANNUAL DFU FOOTWEAR &amp; RISK EVALUATION:<br>'
          if (footwearEvaluationDate) {
            finalNoteBody += `Evaluation on: ${footwearEvaluationDate}.<br>`
          }
          if (footwearEvaluation === 'not eligible') {
            finalNoteBody += 'Clinician documented that the patient was not an eligible candidate for footwear evaluation measure.  '
          } else if (footwearEvaluation === 'exam performed') {
            if (this.$custom.itemTitleContains(footwearNotableIssues, 'None')) {
              if (footwearEvaluationDate) {
                finalNoteBody += `Evaluation on ${
                  footwearEvaluationDate
                } revealed no specific issues related to inappropriate footwear and DFU risk.`
              } else {
                finalNoteBody += 'No specific issues related to inappropriate footwear and DFU risk were noted.  '
              }
            } else {
              finalNoteBody += `${
                this.$custom.toProperCase(
                  this.$custom.toListCommaAnd(footwearNotableIssues),
                )} ${this.$custom.randomString(['factors that increase risk for diabetic foot ulcer (DFU) related to footwear were noted.  ',
                'factors contributing to diabetic foot ulcer (DFU) risk related to footwear were noted.  ',
                'factors that contribute to diabetic foot ulcers (DFU) related to footwear were observed.  ',
                'issues that are related to diabetic foot ulcer (DFU) risk related to footwear were seen.  ',
                'issues that are related to increased risk for diabetic foot ulcers (DFU) related to footwear were seen.  '])}`
            }
            if (footwearExamReason === 'Footwear change recommended') {
              if (this.$custom.itemTitleContains(footwearNotableIssues, 'None')) {
                finalNoteBody += `${this.$custom.randomString([
                  'A change of footwear is nevertheless recommended.  ',
                  'A change of footwear is recommended as a preventive measure.  ',
                ])}`
              } else {
                finalNoteBody += `${this.$custom.randomString([
                  'Examination indicated signs of inappropriate footwear. A change for better fit is recommended.  ',
                  'A footwear change is recommended.  ',
                  'Upon inspection, inappropriate footwear was observed and a change is recommended.  ',
                  'Provider recommends a footwear change to reduce risk of DFU.  ',
                  'Inappropriate footwear was observed and a change is recommended to reduce risk of DFU.  ',
                ])}`
              }
            }
          }
          if (footwearEvaluation === 'not accepted') {
            finalNoteBody += `Reason: ${footwearEvaluationReason}`
          }
        }
      }
      if (this.patient.wounds) {
        this.patient.wounds.forEach(wound => {
          const treatment = wound.treatments[wound.treatments.findIndex(
            x => x.encounter_id === encounterId && x.deleted !== true,
          )]
          if (treatment === undefined) {
            return
          }
          finalNoteBody += '<br><br><hr>'
          const procedureId = treatment ? treatment.procedure_id : null
          const woundTreatment = wound.practice_type_id === 1
            ? treatment.wound_treatment : null
          const gTubeTreatment = wound.practice_type_id === 5 && treatment
            ? treatment.g_tube_treatment : null
          const woundTreatmentWoundSize = woundTreatment
            ? woundTreatment.wound_treatment_wound_size : null
          const dermTreatment = treatment && treatment.derm_treatment
            ? treatment.derm_treatment : null
          const woundStage = woundTreatment ? treatment
            .wound_treatment
            .wound_treatment_wound_size
            .wound_stage : null
          let preDebrideLength = null
          let preDebrideWidth = null
          let preDebrideDepth = null
          let preDebrideArea = null
          let postDebrideLength = null
          let postDebrideWidth = null
          let postDebrideDepth = null
          let postDebrideArea = null
          let postDebridePercent = null
          let postDebrideAreaDebride = null
          if (woundTreatmentWoundSize) {
            preDebrideLength = woundTreatmentWoundSize.primary_length ?? '--'
            preDebrideWidth = woundTreatmentWoundSize.primary_width ?? '--'
            preDebrideDepth = woundTreatmentWoundSize.primary_depth ?? '--'
            preDebrideArea = woundTreatmentWoundSize.primary_area ?? '--'
            postDebrideLength = woundTreatmentWoundSize.post_debridement_length ?? '--'
            postDebrideWidth = woundTreatmentWoundSize.post_debridement_width ?? '--'
            postDebrideDepth = woundTreatmentWoundSize.post_debridement_depth ?? '--'
            postDebrideArea = woundTreatmentWoundSize.post_debridement_area ?? '--'
            postDebridePercent = woundTreatmentWoundSize.post_debridement_percent ? `${woundTreatmentWoundSize.post_debridement_percent}%` : '--'
            postDebrideAreaDebride = woundTreatmentWoundSize.post_debridement_area_debrided ?? '--'
          }
          const isDebrided = (procedureId >= 100
              && procedureId <= 142)
              || procedureId === 160
          finalNoteBody += `${
            this.$store.getters['baseData/practiceTypeFromId'](
              wound.practice_type_id,
            ).title?.toUpperCase()}`
          if (wound.practice_type_id === 1 || wound.practice_type_id === 6) {
            finalNoteBody += `<br><br>LOCATION: ${
              wound.location?.location_text}<br><br>`
            finalNoteBody += `ETIOLOGY: ${treatment.etiology === 'Other' ? treatment.etiology_other : treatment.etiology}${woundStage ? ` - Wound Stage: ${woundStage}` : ''}`
          }
          if (wound.practice_type_id === 6) {
            if (dermTreatment) {
              if (dermTreatment.lesion_conditions) {
                finalNoteBody += '<br><br>LESION CONDITION:<br>'
                finalNoteBody += `${
                  this.$custom.toListComma(dermTreatment.lesion_conditions, dermTreatment.lesion_conditions_other, 'none')
                }`
              }
              if (dermTreatment.lesion_description) {
                finalNoteBody += '<br><br>LESION DESCRIPTION:<br>'
                finalNoteBody += `${
                  dermTreatment.lesion_description
                }`
              }
              if (dermTreatment.duration) {
                finalNoteBody += '<br><br>DURATION:<br>'
                finalNoteBody += `${
                  dermTreatment.duration
                }`
              }
            }
          }
          if (wound.practice_type_id === 1) {
            if (this.$custom.notEmpty(woundTreatment?.preoperative_indications)) {
              finalNoteBody += '<br><br>PREOPERATIVE INDICATIONS:<br>'
              finalNoteBody += `${
                this.$custom.toListCommaAnd(woundTreatment.preoperative_indications)
              }`
            }
            if (woundTreatment?.infection_signs) {
              finalNoteBody += '<br><br>SIGNS OF INFECTION:<br>'
              finalNoteBody += `${
                this.$custom.toListCommaAnd(woundTreatment.infection_signs)}`
            }
          }
          if (wound.practice_type_id === 5) {
            if (gTubeTreatment) {
              if (gTubeTreatment.abdominal_exam) {
                finalNoteBody += '<br><br>ABDOMINAL EXAM:<br>'
                finalNoteBody += `${
                  gTubeTreatment.abdominal_exam
                }`
              }
              if (gTubeTreatment.g_tube_type) {
                finalNoteBody += '<br><br>G-TUBE TYPE:<br>'
                finalNoteBody += `${
                  gTubeTreatment.g_tube_type
                }`
              }
              if (gTubeTreatment.peri_tube_finding) {
                finalNoteBody += '<br><br>PERI-TUBE FINDING:<br>'
                finalNoteBody += `${
                  gTubeTreatment.peri_tube_finding
                }`
              }
              if (gTubeTreatment.duration) {
                finalNoteBody += '<br><br>DURATION:<br>'
                finalNoteBody += `${
                  gTubeTreatment.duration
                }`
              }
              if (gTubeTreatment.peri_tube_tract) {
                finalNoteBody += '<br><br>PERI-TUBE TRACT:<br>'
                finalNoteBody += `${
                  gTubeTreatment.peri_tube_tract
                }`
              }
            }
          }
          if (procedureId) {
            finalNoteBody += '<br><br>PROCEDURE PERFORMED:<br>'
            finalNoteBody += `${
              this
                .$store
                .getters['baseData/procedureFromId'](procedureId)
                .replace(/\[\d+\]/, '')
            }`
          }

          // post_debridement_area_debrided
          const areaDebrided = woundTreatment ? treatment
            .wound_treatment
            .wound_treatment_wound_size
            .post_debridement_area_debrided : null
          if (this.$custom.notEmpty(areaDebrided) && Number(areaDebrided) > 0) {
            finalNoteBody += `  A total area of ${
              areaDebrided
            } sq. cm of devitalized tissue was debrided`
          }

          const estimatedBloodLossId = treatment
            .operative_note_wizard ? treatment
              .operative_note_wizard
              .estimated_blood_loss_id : null

          if (procedureId !== 999 && estimatedBloodLossId) {
            if (estimatedBloodLossId > 1) {
              finalNoteBody += `  with ${
                this.getBoneLossById(estimatedBloodLossId)
              } cc estimated blood loss.  `
            } else {
              finalNoteBody += `${isDebrided ? '.  There was ' : ' with '} no loss of blood.  `
            }
          }
          if (woundTreatmentWoundSize) {
            if (isDebrided) {
              finalNoteBody += `  The pre-op wound area was ${this.$custom.randomString([
                'found to be ',
                'measured ',
                'evaluated to be ',
                'estimated to be ',
                'measured at ',
                'found to be ',
                'evaluated to be ',
              ])}  ${
                preDebrideLength || ''} cm x ${
                preDebrideWidth || ''} cm x ${
                preDebrideDepth || ''} cm (${
                preDebrideArea || ''} sq cm).  `
              finalNoteBody += `The post-op wound area was ${this.$custom.randomString([
                'found to be ',
                'measured ',
                'evaluated to be ',
                'estimated to be ',
                'measured at ',
                'found to be ',
                'evaluated to be ',
              ])}${
                postDebrideLength || ''} cm x ${
                postDebrideWidth || ''} cm x ${
                postDebrideDepth || ''} cm (${
                postDebrideArea || ''} sq cm).  `
            }
          }

          if (procedureId === 999 && woundTreatment
            && (woundTreatment.debridement_not_performed_reasons.length > 0
                || woundTreatment.debridement_not_performed_reason_other)
          ) {
            finalNoteBody += '<br><br>REASON FOR NO DEBRIDEMENT:<br>'
            finalNoteBody += `${
              this.$custom.toListCommaAnd(
                woundTreatment.debridement_not_performed_reasons,
                woundTreatment.debridement_not_performed_reason_other,
              )
            }`
          }

          // part m
          if (wound.practice_type_id === 5 && gTubeTreatment) {
            if (gTubeTreatment.replacement_tube_type) {
              finalNoteBody += '<br><br>REPLACEMENT TUBE TYPE:<br>'
              finalNoteBody += `${gTubeTreatment.replacement_tube_type}`
            }
            if (gTubeTreatment.replacement_size) {
              finalNoteBody += '<br><br>SIZE (French):<br>'
              finalNoteBody += `${gTubeTreatment.replacement_size}`
            }
            if (gTubeTreatment.balloon_cc) {
              finalNoteBody += '<br><br>BALLOON:<br>'
              finalNoteBody += `${gTubeTreatment.balloon_cc}`
            }
            if (gTubeTreatment.replacement_reason) {
              finalNoteBody += '<br><br>REASON FOR REPLACEMENT:<br>'
              finalNoteBody += `${gTubeTreatment.replacement_reason}`
            }
            if (gTubeTreatment.verification_of_placement_1
            || gTubeTreatment.verification_of_placement_2
            || gTubeTreatment.verification_of_placement_3
            || gTubeTreatment.verification_of_placement_other
            ) {
              finalNoteBody += '<br><br>VERIFICATION OF PLACEMENT:<br>'
              finalNoteBody += `${
                this.$custom.toListComma(
                  [
                    gTubeTreatment.verification_of_placement_1,
                    gTubeTreatment.verification_of_placement_2,
                    gTubeTreatment.verification_of_placement_3,
                    gTubeTreatment.verification_of_placement_other,
                  ],
                )
              }`
            }
            if (procedureId !== 999 && treatment.operative_note) {
              finalNoteBody += '<br><br>OPERATIVE NOTE:<br>'
              finalNoteBody += `${this.$custom.textAreaHtml(treatment.operative_note)}`
            }
          }
          if (woundTreatment || dermTreatment) {
            if (woundTreatment) {
              if (woundTreatment.dressing_used_1
              || woundTreatment.dressing_used_2
              || woundTreatment.dressing_used_other
              ) {
                finalNoteBody += '<br><br>DRESSING USED:<br>'
                finalNoteBody += `${
                  this.$custom.toListCommaAnd(
                    [
                      woundTreatment.dressing_used_1,
                      woundTreatment.dressing_used_2,
                      woundTreatment.dressing_used_other,
                    ].filter(x => x !== null), null, 'none',
                  )
                }`

                if ([
                  woundTreatment.dressing_used_1,
                  woundTreatment.dressing_used_2,
                  woundTreatment.dressing_used_other,
                ].filter(x => x !== null && x?.toLowerCase().includes('dakin')).length > 0) {
                  finalNoteBody += '<br><br>REASON FOR USING DAKIN\'S SOLUTION:<br>'
                  const dakinSolutionReason = woundTreatment.reason_for_dakins_solution
                  finalNoteBody += `${dakinSolutionReason}`
                }
                if (woundTreatment.wound_description_summary) {
                  finalNoteBody += '<br><br>WOUND DESCRIPTION:<br>'
                  finalNoteBody += `${this.$custom.textAreaHtml(woundTreatment.wound_description_summary)}`
                }
                if (woundTreatmentWoundSize) {
                  finalNoteBody += '<br><br>TISSUE TYPES BY PERCENTAGE:<br>'
                  finalNoteBody += `${
                    woundTreatmentWoundSize.slough || '0'
                  }% slough, ${
                    woundTreatmentWoundSize.granulation || '0'
                  }% granulation, ${
                    woundTreatmentWoundSize.necrotic_tissue || '0'
                  }% necrotic tissue, ${
                    woundTreatmentWoundSize.hyper_granulation || '0'
                  }% hypergranulation tissue, ${
                    woundTreatmentWoundSize.eschar || '0'
                  }% eschar, ${
                    woundTreatmentWoundSize.epithelial || '0'
                  }% epithelial tissue, ${
                    woundTreatmentWoundSize.dermal || '0'
                  }% dermal `
                }
                finalNoteBody += '<br><br>SIZE:<br>'
                finalNoteBody += `<table width="100%" border="1" cellspacing="0">
                                    <tr>
                                      <td></td>
                                      <td style="padding:6px">Length (cm)</td>
                                      <td style="padding:6px">Width (cm)</td>
                                      <td style="padding:6px">Depth (cm)</td>
                                      <td style="padding:6px">Area (cm<sup>2</sup>)</td>
                                      <td style="padding:6px">Debrided %</td>
                                      <td style="padding:6px">Debrided Area (cm<sup>2</sup>)</td>
                                    </tr>
                                    <tr>
                                      <td style="padding:6px">PRIMARY</td>
                                      <td style="padding:6px">${preDebrideLength}</td>
                                      <td style="padding:6px">${preDebrideWidth}</td>
                                      <td style="padding:6px">${preDebrideDepth}</td>
                                      <td style="padding:6px">${preDebrideArea}</td>
                                      <td style="padding:6px">--</td>
                                      <td style="padding:6px">--</td>
                                    </tr>
                                    <tr>
                                      <td style="padding:6px">POST-OP</td>
                                      <td style="padding:6px">${postDebrideLength}</td>
                                      <td style="padding:6px">${postDebrideWidth}</td>
                                      <td style="padding:6px">${postDebrideDepth}</td>
                                      <td style="padding:6px">${postDebrideArea}</td>
                                      <td style="padding:6px">${postDebridePercent}</td>
                                      <td style="padding:6px">${postDebrideAreaDebride}</td>
                                    </tr>
                                  </table>`
                if (woundTreatment.progress) {
                  finalNoteBody += '<br>WOUND PROGRESS:<br>'
                  finalNoteBody += `${woundTreatment.progress === 'Other' && woundTreatment.progress_other ? woundTreatment.progress_other : woundTreatment.progress || ''}`
                }

                if (treatment.operative_note) {
                  finalNoteBody += '<br><br>OPERATIVE NOTE:<br>'
                  finalNoteBody += `${this.$custom.textAreaHtml(treatment.operative_note)}`
                }
              }
            } else if (dermTreatment) {
              if (dermTreatment.topical_agent
              || dermTreatment.topical_agent_strength
              || dermTreatment.secondary_topical_agent
              || dermTreatment.secondary_topical_agent_strength
              ) {
                finalNoteBody += '<br><br>TOPICAL AGENTS USED:<br>'
                const topicalAgents = [
                  dermTreatment.topical_agent
                                        + (dermTreatment.topical_agent_strength
                                          ? ` (strength: ${dermTreatment.topical_agent_strength})`
                                          : ' '),
                  dermTreatment.secondary_topical_agent
                                        + (dermTreatment.secondary_topical_agent_strength
                                          ? ` (strength: ${dermTreatment.secondary_topical_agent_strength})`
                                          : ' '),
                ].filter(x => x !== null && x !== 'null ')
                finalNoteBody += `${this.$custom.toListCommaAnd(topicalAgents, null, 'none')}`
              }
              if (procedureId !== 999 && treatment.operative_note) {
                finalNoteBody += '<br><br>OPERATIVE NOTE:<br>'
                finalNoteBody += `${this.$custom.textAreaHtml(treatment.operative_note)}`
              }
            }
          }
        })
      }
      this.final_note.body = this.$custom.clean(finalNoteBody, 'Wound Body', false)
    },
  },
}
